<template>
  <div class="readme-article">
    <h1 id="调配规则">
      四、调配规则
    </h1>
    <h2 id="线索回收规则">1.线索回收规则</h2>
    <p>设置线索回收规则，满足条件的招生线索会回收到线索公海里</p>
    <p>
      <img src="@/assets/img/recruit/4-1.png">
    </p>
    <h2 id="线索领取规则">2.线索领取规则</h2>
    <p>设置线索领取规则，线索公海里的线索需满足领取条件才可领取</p>
    <p>
      <img src="@/assets/img/recruit/4-2.png">
    </p>
    <h2 id="线索锁定规则">3.线索锁定规则</h2>
    <p>设置线索锁定规则，招生线索中的线索可进行锁定，锁定后将不会回收到公海</p>
    <p>
      <img src="@/assets/img/recruit/4-3.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Recruit4-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>